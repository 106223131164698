const hubRoutes = {
  HUB_DOCS_INTRO: '/quero-economizar/($hubFlow)/$leadId/documento',
  HUB_DOCS_CHOICE: '/quero-economizar/($hubFlow)/$leadId/documento/selecionar',
  HUB_DOCS_TYPE: '/quero-economizar/($hubFlow)/$leadId/documento/$docType',
  HUB_BILL_INTRO:
    '/quero-economizar/($hubFlow)/$leadId/conta-de-luz/introducao',
  HUB_BILL_INSTALLATION_NUMBER:
    '/quero-economizar/($hubFlow)/$leadId/conta-de-luz/numero-de-instalacao',
  HUB_BILL_BILL_CLIENT_NUMBER:
    '/quero-economizar/($hubFlow)/$leadId/conta-de-luz/numero-de-cliente',
  HUB_BILL_BILL_UPLOAD:
    '/quero-economizar/($hubFlow)/$leadId/conta-de-luz/upload-conta',

  // offer flow
  HUB_PREPARING_OFFER: '/quero-economizar/($hubFlow)/$leadId/preparando-oferta',
  HUB_OFFER: '/quero-economizar/($hubFlow)/$leadId/oferta',

  // Select alternative owner flow
  HUB_SELECT_OWNERSHIP_PROCESS:
    '/quero-economizar/($hubFlow)/$leadId/opcoes-de-titularidade',

  // Send to owner flow
  HUB_SEND_TO_OWNER: '/quero-economizar/($hubFlow)/$leadId/enviar-ao-titular',
  HUB_PRE_REGISTER_OWNER:
    '/quero-economizar/($hubFlow)/$leadId/dados-do-titular',
  HUB_OWNER_SUCCESS: '/quero-economizar/($hubFlow)/$leadId/link-gerado',

  HUB_OWNER_COMPLETE_REGISTRATION:
    '/quero-economizar/($hubFlow)/$leadId/finalizar-cadastro',
  HUB_OWNER_CONFIRM_REGISTER:
    '/quero-economizar/($hubFlow)/$leadId/confirmar-cadastro',

  // Change ownership flow
  HUB_OWNER_DOCUMENT_CONFIRM:
    '/quero-economizar/($hubFlow)/$leadId/confirmar-identidade',
  HUB_OWNER_DOCUMENT_CONFIRMATED:
    '/quero-economizar/($hubFlow)/$leadId/identidade-confirmada',
  HUB_OWNER_TALK_TO_SPECIALIST:
    '/quero-economizar/($hubFlow)/$leadId/falar-especialista',
  HUB_OWNER_PREPARING_SIGNATURE:
    '/quero-economizar/($hubFlow)/$leadId/preparando-assinatura',
  HUB_OWNER_SIGNATURE: '/quero-economizar/($hubFlow)/$leadId/assinatura',
} as const

const appRoutes = {
  PARTNERS_LANDING_PAGE: '/$landingPath',
  TERMS_OF_USE: '/termos-de-uso',
  TERMS_OF_SERVICE: '/termos-de-servico',
  LEADS: '/cadastro',
  ACQUISITION_FLOW_PARTNER_LOADING: '/parceiros/$partnerId/$leadId',
  ACQUISITION_FLOW_PARTNER_CONFIRM_REGISTER:
    '/parceiros/$partnerId/$leadId/confirmar',
  ACQUISITION_FLOW_HOME: '/quero-economizar',
  ACQUISITION_FLOW_REGISTER_EMAIL:
    '/quero-economizar/cadastro/conta/$leadId/email',
  ACQUISITION_FLOW_REGISTER_PLACE_CATEGORY:
    '/quero-economizar/cadastro/conta/$leadId/lugar',
  ACQUISITION_FLOW_REGISTER_PLACE_DETAILS:
    '/quero-economizar/cadastro/conta/$leadId/lugar/$placeCategory/detalhes',
  ACQUISITION_FLOW_REGISTER_PERSON_DATA:
    '/quero-economizar/cadastro/seus-dados',
  ACQUISITION_FLOW_REGISTER_CPF: '/quero-economizar/cadastro/conta/$leadId/cpf',
  ACQUISITION_FLOW_REGISTERED:
    '/quero-economizar/cadastro/conta/$leadId/cadastrado',
  ACQUISITION_FLOW_PLACE_ADDRESS:
    '/quero-economizar/cadastro/conta/$leadId/lugar/endereco',
  ACQUISITION_FLOW_PLACE_ADDRESS_EDIT:
    '/quero-economizar/cadastro/conta/$leadId/lugar/endereco/editar',
  ACQUISITION_FLOW_PLACE_CONFIRM_CUSTOMER_UNIT:
    '/quero-economizar/cadastro/conta/$leadId/lugar/endereco/confirmar',
  ACQUISITION_FLOW_PLACE_SELECT_CUSTOMER_UNIT:
    '/quero-economizar/cadastro/conta/$leadId/lugar/endereco/selecionar',
  ACQUISITION_FLOW_BILL_OWNER:
    '/quero-economizar/cadastro/conta/$leadId/titularidade',
  ACQUISITION_FLOW_BILL_DOCUMENT:
    '/quero-economizar/cadastro/conta/$leadId/cnpj',
  ACQUISITION_FLOW_COMPANY_FULL_NAME:
    '/quero-economizar/cadastro/conta/$leadId/razao-social',
  ACQUISITION_FLOW_BILL_INSTALLATION_NUMBER:
    '/quero-economizar/cadastro/conta/$leadId/dados-da-conta/numero-de-instalacao',
  ACQUISITION_FLOW_BILL_CLIENT_NUMBER:
    '/quero-economizar/cadastro/conta/$leadId/dados-da-conta/numero-de-cliente',
  ACQUISITION_FLOW_BILL_UPLOAD:
    '/quero-economizar/cadastro/conta/$leadId/upload-conta',
  ACQUISITION_FLOW_SIGNATURE_INDEX: '/quero-economizar/cadastro/assinatura',
  ACQUISITION_FLOW_SIGNATURE_ID: '/quero-economizar/cadastro/assinatura/$id',
  ACQUISITION_FLOW_SIGNATURE_NEW:
    '/quero-economizar/cadastro/assinatura/$id/nova',
  ACQUISITION_FLOW_SIGNATURE_EMBED:
    '/quero-economizar/cadastro/assinatura/$id/assinar',
  ACQUISITION_FLOW_DOCUMENT_INTRO:
    '/quero-economizar/cadastro/conta/$leadId/documento',
  ACQUISITION_FLOW_DOCUMENT_CHOICE:
    '/quero-economizar/cadastro/conta/$leadId/documento/selecionar',
  ACQUISITION_FLOW_DOCUMENT_TYPE:
    '/quero-economizar/cadastro/conta/$leadId/documento/$docType',
  ACQUISITION_FLOW_WAIT_LIST: '/quero-economizar/lista-espera',
  ACQUISITION_FLOW_WAIT_LIST_FINISH:
    '/quero-economizar/lista-espera/cadastrado',
  ACQUISITION_FLOW_SUCCESS: '/quero-economizar/cadastro/conta/$leadId/sucesso',
  ...hubRoutes,
} as const

export default appRoutes
